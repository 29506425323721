import { CarrierOrder, CarrierOrderPaymentMethod } from "api/logistics/carrierOrders/models";
import { dateFns, queryString } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiViewPdf } from "components/miloDesignSystem/atoms/icons/MdiViewPdf";
import { useSelector } from "hooks";
import { routeActions } from "api/routes/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { carrierOrdersPreviewFactory } from "api/logistics/carrierOrders/api";

export const useCarrierOrdersListColumns = (carrierOrders: CarrierOrder[]) => {
  const me = useSelector(store => store.auth.user!);
  const { data: routePostDeliveryInfos, isLoading } = routeActions.useRoutePostDeliveryInfos(
    queryString.stringify({ routes: carrierOrders.map(order => order.route.id) }),
  );

  return useCreateTableColumns<CarrierOrder>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.route.signature, {
        header: "trasa",
        size: 120,
      }),
      columnHelper.text(row => row.carrier.companyName, {
        header: "przewoźnik",
        size: 200,
      }),
      columnHelper.text(row => dateFns.format(new Date(row.createdAt), "dd.MM.yyyy, HH:mm"), {
        header: "data zlecenia",
        size: 130,
      }),
      columnHelper.accessor(row => row.createdBy, {
        header: "zlecono przez",
        size: 150,
        cell: info => {
          const createdBy: CarrierOrder["createdBy"] = info.getValue();
          if (!createdBy) return <EmptyValue />;
          return (
            <>
              <Avatar size="sm" user={createdBy} theme="light" />
              <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
                {createdBy.firstName} {createdBy.lastName}
              </Typography>
            </>
          );
        },
      }),
      columnHelper.accessor(row => row.route, {
        header: "PDF",
        size: 50,
        cell: info => {
          const route = info.getValue();

          return (
            <div>
              <IconButton
                icon={<MdiViewPdf size="22" />}
                onClick={event => {
                  event.stopPropagation();
                  window.open(
                    carrierOrdersPreviewFactory.carrierOrderPDF(
                      queryString.stringify({
                        routesIds: route.id,
                        userId: me.id,
                      }),
                    ).url,
                    "_blank",
                  );
                }}
                variant="gray"
              />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.car.registrationNumber, {
        header: "nr rejestracyjny",
        size: 100,
      }),
      columnHelper.text(
        row => {
          const drivers = row.drivers;
          if (!drivers.length) return null;
          return drivers.map(driver => `${driver.firstName} ${driver.lastName}`).join(",");
        },
        {
          header: "kierowcy",
          size: 150,
        },
      ),
      columnHelper.text(() => "---", {
        header: "data załadunku",
        size: 100,
      }),
      columnHelper.text(() => "---", {
        header: "data wyjazdu",
        size: 100,
      }),
      columnHelper.accessor(row => row.paymentMethod, {
        header: "sposób zapłaty",
        size: 100,
        cell: info => {
          if (info.getValue() === "") return <EmptyValue />;
          const data = paymentMethodDict[info.getValue() as CarrierOrderPaymentMethod];
          return <Tag label={data.label} variant={data.variant} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "zdjęcia",
        size: 60,
        cell: info => {
          const carrierOrder = info.getValue();
          if (isLoading) return <Spinner size={12} />;

          const data = routePostDeliveryInfos?.find(
            infos => infos.id === Number(carrierOrder.route.id),
          );

          return (
            <Typography fontSize="14" fontWeight="400">
              {data?.photoCount}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "kody odbioru",
        size: 80,
        cell: info => {
          const carrierOrder = info.getValue();
          if (isLoading) return <Spinner size={12} />;

          const data = routePostDeliveryInfos?.find(
            infos => infos.id === Number(carrierOrder.route.id),
          );

          return (
            <div className="d-flex align-items-center">
              <Typography fontSize="14" fontWeight="400">
                {data?.pickupCodeCount}
              </Typography>
              <Typography fontSize="14" fontWeight="400">
                /{carrierOrder.orders.length}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "podpisy",
        size: 60,
        cell: info => {
          const carrierOrder = info.getValue();
          if (isLoading) return <Spinner size={12} />;

          const data = routePostDeliveryInfos?.find(
            infos => infos.id === Number(carrierOrder.route.id),
          );

          return (
            <div className="d-flex align-items-center">
              <Typography fontSize="14" fontWeight="400">
                {data?.signatureCount}
              </Typography>
              <Typography fontSize="14" fontWeight="400">
                /{carrierOrder.orders.length}
              </Typography>
            </div>
          );
        },
      }),
    ];
  });
};

export const paymentMethodDict: Record<
  CarrierOrderPaymentMethod | "",
  { label: string; variant: "warning" | "info" }
> = {
  [CarrierOrderPaymentMethod.CASH]: { label: "gotówka", variant: "warning" },
  [CarrierOrderPaymentMethod.ONLINE]: { label: "przelew", variant: "info" },
  "": { label: "---", variant: "info" },
};

import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { wmsLayoutKeys } from "./keys";
import { ApiFetcher } from "hooks/createApiQuery";
import {
  AssignField,
  CollectionDetails,
  CollectionKind,
  CollectionLayout,
  FieldDetails,
  FillingStatus,
  Hall,
  PackageInLocation,
  RampStatus,
  SearchCollection,
  SearchCollectionOverview,
} from "./models";
import { createPreviewFactory } from "utilities/createPreviewMiddleware";

const getSearchCollections = (search: string = ""): ApiFetcher<Pagination<SearchCollection>> => ({
  key: wmsLayoutKeys.getSearchCollections(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/hall-schemas/search-collections" + search,
    }),
});

const getPackagesInLocation = (search: string = ""): ApiFetcher<Pagination<PackageInLocation>> => ({
  key: wmsLayoutKeys.getPackagesInLocation(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/hall-schemas/packages-in-location" + search,
    }),
});

const getLayoutSchema = (id: number): ApiFetcher<Hall> => ({
  key: wmsLayoutKeys.getLayoutSchema(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wh-halls/items/${id}`,
    }),
});

const getCollectionDetails = ({
  id,
  kind,
}: {
  id: number;
  kind: CollectionKind;
}): ApiFetcher<CollectionDetails> => ({
  key: wmsLayoutKeys.collectionDetails(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/collection-info/${kind}/${id}`,
    }),
});

const getRampStatus = (hallId: number): ApiFetcher<RampStatus[]> => ({
  key: wmsLayoutKeys.getRampStatus(hallId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/items/${hallId}/ramps-status`,
    }),
});

const getFillingStatus = (hallId: number): ApiFetcher<FillingStatus> => ({
  key: wmsLayoutKeys.getFillingStatus(hallId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/items/${hallId}/filling-status`,
    }),
});

const getSchemaSearchCollections = (search: string = ""): ApiFetcher<SearchCollectionOverview> => ({
  key: wmsLayoutKeys.getSchemaSearchCollections(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/hall-schemas/v2/search-collections" + search,
    }),
});

const getCollectionLayout = ({
  hallId,
  collectionId,
  kind,
}: {
  kind: CollectionKind;
  hallId: number;
  collectionId: number;
}): ApiFetcher<CollectionLayout> => ({
  key: wmsLayoutKeys.collectionLayout(hallId, collectionId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/items/${hallId}/collections/${kind}/${collectionId}`,
    }),
});

const getLayoutField = ({
  hallId,
  nodeId,
}: {
  hallId: number;
  nodeId: UUID;
}): ApiFetcher<FieldDetails> => ({
  key: wmsLayoutKeys.getLayoutField(hallId, nodeId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/wms/hall-schemas/locations/${nodeId}`,
    }),
});

const patchField = (data: Partial<AssignField>, id: UUID) =>
  queryFetch<FieldDetails>({
    method: "PATCH",
    url: "/wms/hall-schemas/locations/" + id,
    data,
  });

export const wmsLayoutPreviewFactory = createPreviewFactory({
  whRowcellsLabels: (rowId: number) => ({
    url: `/wh-rows/cells-labels/${rowId}`,
  }),
  whCellsLabels: (cellId: number | string) => ({
    url: `/wh-cells/labels/${cellId}`,
  }),
});

export const wmsLayoutApi = {
  getCollectionDetails,
  getLayoutSchema,
  getSearchCollections,
  getRampStatus,
  getCollectionLayout,
  getFillingStatus,
  getLayoutField,
  getPackagesInLocation,
  patchField,
  getSchemaSearchCollections,
};

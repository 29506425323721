import {
  CorrectionReasonAction,
  CorrectionReasonField,
  ExpectedPaymentForm,
  InvoiceReviewerStatus,
  InvoiceType,
  ReceiptPrintingStatus,
  ReviewStatus,
  TaxProcedure,
  TradingDocument,
  TradingDocumentKind,
  TradingDocumentNotificationAction,
  TradingDocumentNotificationStatus,
  TradingDocumentPaymentKind,
  TradingDocumentPaymentProvider,
  TradingDocumentPaymentStatus,
  TradingDocumentPaymentType,
  TradingDocumentPdfProcessingStatus,
  TradingDocumentStatus,
  TradingDocumentType,
  ViesStatus,
} from "api/trading-documents/models";
import changesRequestedIcon from "assets/images/ruleOrange.svg";
import acceptedIcon from "assets/images/checkTeal.svg";
import awaitingIcon from "assets/images/radioButtonPartial.svg";
import { TagProps } from "components/miloDesignSystem/atoms/tag";
import { colorType } from "components/common/stateLabel";
import { dictToList, generateDictBasedOnFactoryAndEnum } from "utilities";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ExternalService, GTUCode } from "api/trading-documents/enums";
import optima from "assets/images/optima.png";

const invoiceTypeDict: Record<InvoiceType, string> = {
  CORRECTION: "korekta",
  FINAL: "końcowa",
  ADVANCE: "zaliczkowa",
  PROFORMA: "proforma",
  PURCHASE: "zakupowa",
  RECEIPT: "paragon",
  RECEIPT_BASED_ON_INVOICE: "paragon",
  SALES: "sprzedażowa",
};

const invoiceTypeOptions: { id: InvoiceType; name: string }[] = Object.entries(invoiceTypeDict)
  .filter(([id]) => id !== "RECEIPT" && id !== "RECEIPT_BASED_ON_INVOICE")
  .map(([id, name]) => ({
    id: id as InvoiceType,
    name,
  }));

const invoiceStatusDict: Record<TradingDocumentStatus, string> = {
  CONFIRMED: "Zatwierdzona",
  NEW: "Nowa",
  WORK_IN_PROGRESS: "Robocza",
};

const receiptStatusDict: Record<TradingDocumentStatus, string> = {
  CONFIRMED: "Zatwierdzony",
  NEW: "Nowy",
  WORK_IN_PROGRESS: "Roboczy",
};

const tradingDocumentStatusVariant: Record<
  TradingDocumentStatus,
  Record<TradingDocumentType, { label: string; variant: TagProps<string>["variant"] }>
> = {
  CONFIRMED: {
    INVOICE: { label: invoiceStatusDict["CONFIRMED"], variant: "success" },
    RECEIPT: { label: receiptStatusDict["CONFIRMED"], variant: "success" },
  },
  NEW: {
    INVOICE: { label: invoiceStatusDict["NEW"], variant: "info" },
    RECEIPT: { label: receiptStatusDict["NEW"], variant: "info" },
  },
  WORK_IN_PROGRESS: {
    INVOICE: { label: invoiceStatusDict["WORK_IN_PROGRESS"], variant: "warning" },
    RECEIPT: { label: receiptStatusDict["WORK_IN_PROGRESS"], variant: "warning" },
  },
};

const viesStatusDict: Record<ViesStatus, string> = {
  ACTIVE: "Aktywny",
  INACTIVE: "Nieaktywny",
  UNREGISTERED: "Niezarejestrowany",
  NO_DATA: "Brak danych",
  NO_TAX_ID: "Brak NIP-u",
  INVALID: "Nieznany",
};

const invoicePaymentStatusDict: Record<TradingDocumentPaymentStatus, string> = {
  NOT_PAID: "Nieopłacona",
  PAID: "Opłacona",
  PARTIALLY_PAID: "Częściowo opłacona",
};

const invoicePaymentStatusOptions: {
  id: TradingDocumentPaymentStatus;
  name: string;
}[] = Object.entries(invoicePaymentStatusDict).map(([id, name]) => ({
  id: id as TradingDocumentPaymentStatus,
  name,
}));

const invoicePaymentStatusItems = dictToList(invoicePaymentStatusDict).map(({ key, value }) => ({
  text: value,
  type: MenuItemType.TEXT,
  value: key,
}));

const tradingDocumentStatusDict: Record<TradingDocumentStatus, string> = {
  CONFIRMED: "Zatwierdzony",
  NEW: "Nowy",
  WORK_IN_PROGRESS: "Roboczy",
};

const tradingDocumentStatusOptions: { id: TradingDocumentStatus; name: string }[] = Object.entries(
  tradingDocumentStatusDict,
).map(([id, name]) => ({ id: id as TradingDocumentStatus, name }));

const taxProcedureDict: Record<TaxProcedure, string> = {
  NA: "Nie dotyczy",
  OSS: "OSS",
  ISG: "Wewnątrzwspólnotowa Dostawa Towarów",
  DOMESTIC: "Dostawa krajowa",
  EXPORT: "Eksport",
};

const taxProcedureFilterOptions: {
  label: string;
  value: TaxProcedure;
}[] = Object.entries(taxProcedureDict).map(([procedure, label]) => ({
  label,
  value: procedure as TaxProcedure,
}));

const reviewStatusDict: Record<ReviewStatus, string> = {
  OPEN: "Otwarta",
  CLOSED: "Zamknięta",
};

const invoiceReviewerStatusDict: Record<InvoiceReviewerStatus, string> = {
  AWAITING: awaitingIcon,
  ACCEPTED: acceptedIcon,
  CHANGES_REQUESTED: changesRequestedIcon,
};

const tradingDocumentPaymentProviderDict: Record<TradingDocumentPaymentProvider, string> = {
  PAYPRO: "PayPro",
  P24: "Przelewy24",
  PAYU: "PayU",
  BLUE_MEDIA: "BlueMedia",
};

const tradingDocumentPaymentProviderOptions: {
  id: TradingDocumentPaymentProvider;
  name: string;
}[] = Object.entries(tradingDocumentPaymentProviderDict).map(([id, name]) => ({
  id: id as TradingDocumentPaymentProvider,
  name,
}));

const tradingDocumentPaymentTypeDict: Record<TradingDocumentPaymentType, string> = {
  CASH: "gotówka",
  ONLINE: "przelew",
};

const tradingDocumentPaymentTypeOptions: {
  id: TradingDocumentPaymentType;
  name: string;
}[] = Object.entries(tradingDocumentPaymentTypeDict).map(([id, name]) => ({
  id: id as TradingDocumentPaymentType,
  name,
}));

const tradingDocumentPaymentKindDict: Record<TradingDocumentPaymentKind, string> = {
  ADVANCE: "zaliczka",
  OTHER: "inne",
  PAYMENT: "płatność",
  REFUND: "zwrot",
};

const tradingDocumentPaymentKindOptions: {
  id: Omit<TradingDocumentPaymentKind, "OTHER">;
  name: string;
}[] = Object.entries(tradingDocumentPaymentKindDict)
  .filter(([id]) => id !== "OTHER")
  .map(([id, name]) => ({
    id: id as Omit<TradingDocumentPaymentKind, "OTHER">,
    name,
  }));

const expectedPaymentFormDict: Record<ExpectedPaymentForm, string> = {
  CASH: "Gotówka",
  ONLINE: "Przelew",
  "": "nie występuje na dokumencie",
};

const paymentMethodsOptions: {
  label: string;
  value: string;
}[] = Object.entries(expectedPaymentFormDict)
  .filter(([value]) => value !== "")
  .map(([value, label]) => ({
    label,
    value: (value as Omit<ExpectedPaymentForm, "">) as string,
  }));

const tradingDocumentNotificationActionDict: Record<TradingDocumentNotificationAction, string> = {
  STANDARD: "wysłano fakturę",
  CORRECTION: "wysłano korektę",
  CORRECTION_DUPLICATE: "wysłano duplikat korekty",
  DUPLICATE: "wysłano duplikat",
};

const tradingDocumentNotificationStatusDict: Record<TradingDocumentNotificationStatus, string> = {
  OPENED: "Odczytana",
  SENT: "Wysłana",
  DECLINED: "Błąd wysyłki",
  CLICKED_LINK: "Odczytana",
};

const receiptPrintingStatusDict: Record<
  ReceiptPrintingStatus,
  { color: ColorPalette; label: string }
> = {
  NOT_STARTED: { color: "success600", label: "gotowy do druku" },
  IN_PROGRESS: { color: "deepPurple400", label: "drukuje się" },
  FINISHED: { color: "info500", label: "wydrukowano" },
  FAILED: { color: "danger500", label: "błąd drukowania" },
};

const invoiceProcessingStatusDict: Record<TradingDocumentPdfProcessingStatus, string> = {
  NEW: "Nowa",
  IN_PROGRESS: "W toku",
  DONE: "Gotowe",
  ACCEPTED: "Zaakceptowana",
  FAILED: "Niepowodzenie",
  REJECTED: "Odrzucono",
};

const invoiceProcessingStatusColorDict: Record<TradingDocumentPdfProcessingStatus, colorType> = {
  NEW: "transparentViolet",
  IN_PROGRESS: "orange",
  DONE: "green",
  ACCEPTED: "cyan",
  FAILED: "darkRed",
  REJECTED: "red",
};

const tradingDocumentTypeDict: Record<TradingDocumentType, string> = {
  INVOICE: "Faktura",
  RECEIPT: "Paragon",
};

const tradingDocumentTypeOptions: { id: TradingDocumentType; name: string }[] = Object.entries(
  tradingDocumentTypeDict,
).map(([id, name]) => ({
  id: id as TradingDocumentType,
  name,
}));

const paymentProviderOptions: { label: string; value: string }[] = [
  { label: "PAYU", value: "PAYU" },
  { label: "P24", value: "P24" },
  { label: "TPAY", value: "TPAY" },
  { label: "PAYNOW", value: "PAYNOW" },
  { label: "PAYPAL", value: "PAYPAL" },
  { label: "KLARNA", value: "KLARNA" },
];

const correctionReasonsMap: Record<
  CorrectionReasonField,
  Partial<Record<CorrectionReasonAction, string>>
> = {
  POSITION: {
    REMOVED: "Usunięto pozycję",
  },
  QUANTITY: {
    NEW: "Dodanie produktu",
    INCREMENT: "Zwiększenie liczby pozycji",
    DECREMENT: "Zmniejszenie liczby pozycji",
  },
  AMOUNT: {
    NEW: "Dodanie należności za fakturę",
    INCREMENT: "Zwiększenie należności za pozycję faktury",
    DECREMENT: "Zmniejszenie należności za pozycję faktury",
  },
  DISCOUNT: {
    NEW: "Przyznanie rabatu",
    INCREMENT: "Zwiększenie rabatu",
    DECREMENT: "Zmniejszenie rabatu",
  },
  VAT: {
    NEW: "Dodano stawkę VAT",
    INCREMENT: "Błędna stawka VAT, zwiększenie stawki VAT",
    DECREMENT: "Błędna stawka VAT, zmniejszenie stawki VAT",
  },
};

const tradingDocumentTypeToTabDict: Record<InvoiceType, string> = {
  ADVANCE: "advances",
  CORRECTION: "corrections",
  FINAL: "finals",
  PROFORMA: "proformas",
  PURCHASE: "purchases",
  SALES: "sales",
  RECEIPT: "receipts",
  RECEIPT_BASED_ON_INVOICE: "receipts",
};

const tradingDocumentKindDict: Record<TradingDocumentKind, string> = {
  DROPSHIPPING: "dropshipping",
  OWN_ENTITY: "podmiot własny",
  RETAIL: "detaliczny",
  WHOLESALE: "hurtowy",
};

const tradingDocumentKindOptions: { label: string; value: TradingDocumentKind }[] = Object.entries(
  tradingDocumentKindDict,
).map(([value, label]) => ({
  label,
  value: value as TradingDocumentKind,
}));

const getPrintingStatusTagData = (
  status: TradingDocument["printingStatus"],
): { color: ColorPalette; label: string; value: string } => {
  switch (status) {
    case "FAILED":
      return { color: "danger500", label: "błąd drukowania", value: "FAILED" };
    case "NOT_STARTED":
      return { color: "success600", label: "gotowy do druku", value: "NOT_STARTED" };
    case "IN_PROGRESS":
      return { color: "deepPurple400", label: "drukuje się", value: "IN_PROGRESS" };
    case "FINISHED":
      return { color: "info500", label: "wydrukowano", value: "FINISHED" };
    default:
      const exhaustiveCheck = status;
      console.error(`Unhandled printing status: ${exhaustiveCheck}`);
      return { color: "neutralBlack100", label: EMPTY_VALUE, value: "" };
  }
};

const getExternalServiceDetails = (externalService: ExternalService) => {
  switch (externalService) {
    case ExternalService.OPTIMA:
      return {
        label: "Optima",
        icon: optima,
      };
  }
};

const getGTUCodesDescriptions = (gtuCode: GTUCode): string => {
  switch (gtuCode) {
    case GTUCode.GTU_01:
      return "dostawa napojów alkoholowych o zawartości alkoholu powyżej 1,2%, piwa oraz napojów alkoholowych będących mieszaniną piwa i napojów bezalkoholowych, w których zawartość alkoholu przekracza 0,5% (CN od 2203 do 2208)";
    case GTUCode.GTU_02:
      return "dostawa towarów, o których mowa w art. 103 ust. 5aa ustawy";
    case GTUCode.GTU_03:
      return "dostawa olejów opałowych nieujętych w lit. b, olejów smarowych i pozostałych olejów (CN od 2710 19 71 do 2710 19 83 i CN od 2710 19 87 do 2710 19 99, z wyłączeniem smarów plastycznych zaliczonych do kodu CN 2710 19 99), olejów smarowych (CN 2710 20 90) oraz preparatów smarowych (CN 3403, z wyłączeniem smarów plastycznych objętych tą pozycją)";
    case GTUCode.GTU_04:
      return "dostawa wyrobów tytoniowych, suszu tytoniowego, płynu do papierosów elektronicznych i wyrobów nowatorskich, w rozumieniu przepisów o podatku akcyzowym";
    case GTUCode.GTU_05:
      return "dostawa odpadów – wyłącznie określonych w poz. 79–91 załącznika nr 15 do ustawy";
    case GTUCode.GTU_06:
      return "dostawa urządzeń elektronicznych oraz części i materiałów do nich, wyłącznie określonych w poz. 7, 8, 59–63, 65, 66, 69 i 94–96 załącznika nr 15 do ustawy, a także folii typu stretch określonej w poz. 9 tego załącznika";
    case GTUCode.GTU_07:
      return "dostawa pojazdów oraz części (CN od 8701 do 8708)";
    case GTUCode.GTU_08:
      return "dostawa metali szlachetnych oraz nieszlachetnych – wyłącznie określonych w poz. 1 i 1a załącznika nr 12 do ustawy oraz w poz. 12–25, 33–40, 45, 46, 56 i 78 załącznika nr 15 do ustawy";
    case GTUCode.GTU_09:
      return "dostawa produktów leczniczych, środków spożywczych specjalnego przeznaczenia żywieniowego oraz wyrobów medycznych – wyłącznie objętych obowiązkiem zgłoszenia, o którym mowa w art. 37av ust. 1 ustawy z dnia 6 września 2001 r. – Prawo farmaceutyczne (Dz. U. z 2021 r. poz. 974 i 981)";
    case GTUCode.GTU_10:
      return "dostawa budynków, budowli i gruntów oraz ich części i udziałów w prawie własności, w tym również zbycia praw, o których mowa w art. 7 ust. 1 ustawy";
    case GTUCode.GTU_11:
      return "świadczenie usług w zakresie przenoszenia uprawnień do emisji gazów cieplarnianych, o których mowa w ustawie z dnia 12 czerwca 2015 r. o systemie handlu uprawnieniami do emisji gazów cieplarnianych (Dz. U. z 2021 r. poz. 332 i 1047)";
    case GTUCode.GTU_12:
      return "świadczenie usług o charakterze niematerialnym – wyłącznie: doradczych, w tym doradztwa prawnego i podatkowego oraz doradztwa związanego z zarządzaniem (PKWiU 62.02.1, 62.02.2, 66.19.91, 69.20.3, 70.22.11, 70.22.12, 70.22.13, 70.22.14, 70.22.15, 70.22.16, 70.22.3, 71.11.24, 71.11.42, 71.12.11, 71.12.31, 74.90.13, 74.90.15, 74.90.19), w zakresie rachunkowości i audytu finansowego (PKWiU 69.20.1, 69.20.2), prawnych (PKWiU 69.1), zarządczych (PKWiU 62.03, 63.11.12, 66.11.19, 66.30, 68.32, 69.20.4, 70.22.17, 70.22.2, 90.02.19.1), firm centralnych (PKWiU 70.1), marketingowych lub reklamowych (PKWiU 73.1), badania rynku i opinii publicznej (PKWiU 73.2), w zakresie badań naukowych i prac rozwojowych (PKWiU 72) oraz w zakresie pozaszkolnych form edukacji (PKWiU 85.5)";
    case GTUCode.GTU_13:
      return "świadczenie usług transportowych i gospodarki magazynowej (PKWiU 49.4, 52.1)";
    default:
      const exhaustiveCheck = gtuCode;
      console.error(`Unhandled GTU code: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
};

const gtuCodesOptions = generateDictBasedOnFactoryAndEnum(getGTUCodesDescriptions, GTUCode).map(
  ({ key, value }) => ({
    text: (key as unknown) as string,
    type: MenuItemType.TEXT,
    value: (key as unknown) as string,
    helperText: value,
  }),
);

const printingStatusOptions = generateDictBasedOnFactoryAndEnum(
  getPrintingStatusTagData,
  ReceiptPrintingStatus,
).map(({ value }) => ({
  color: value.color,
  label: value.label,
  value: value.value,
}));

export const tradingDocumentConstants = {
  correctionReasonsMap,
  expectedPaymentFormDict,
  getExternalServiceDetails,
  gtuCodesOptions,
  invoicePaymentStatusDict,
  invoicePaymentStatusItems,
  invoicePaymentStatusOptions,
  invoiceProcessingStatusColorDict,
  invoiceProcessingStatusDict,
  invoiceReviewerStatusDict,
  invoiceStatusDict,
  invoiceTypeDict,
  invoiceTypeOptions,
  paymentMethodsOptions,
  paymentProviderOptions,
  printingStatusOptions,
  receiptPrintingStatusDict,
  receiptStatusDict,
  reviewStatusDict,
  taxProcedureDict,
  taxProcedureFilterOptions,
  tradingDocumentNotificationActionDict,
  tradingDocumentNotificationStatusDict,
  tradingDocumentPaymentKindOptions,
  tradingDocumentPaymentProviderDict,
  tradingDocumentPaymentProviderOptions,
  tradingDocumentPaymentTypeDict,
  tradingDocumentPaymentTypeOptions,
  tradingDocumentStatusDict,
  tradingDocumentStatusOptions,
  tradingDocumentStatusVariant,
  tradingDocumentTypeDict,
  tradingDocumentTypeOptions,
  viesStatusDict,
  tradingDocumentTypeToTabDict,
  tradingDocumentKindDict,
  tradingDocumentKindOptions,
};

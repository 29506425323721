import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { uniquePackagesKeys } from "./keys";
import { BulkUpdateLongHeldPackage, LongHeldPackage } from "./models";
import { PartialOf } from "typeUtilities";
import { fileFactoryUtils } from "utilities/fileFactory";

const getLongHeldPackages = (search: string = ""): ApiFetcher<Pagination<LongHeldPackage>> => {
  return {
    key: uniquePackagesKeys.longHeldPackagesWithOrders(search),
    fetcher: () =>
      queryFetch({
        method: "GET",
        url: `/wms/view-modules/unique-packages-in-stock/furniture` + search,
      }),
  };
};

const patchLongHeldPackage = (data: PartialOf<LongHeldPackage>, id: UUID) =>
  queryFetch<LongHeldPackage>({
    method: "PATCH",
    url: `/orders/long-held-packages/${id}`,
    data,
  });

const postBulkUpdateLongHeldPackages = (data: BulkUpdateLongHeldPackage) =>
  queryFetch<void>({
    method: "POST",
    url: "/orders/bulk-update-long-held-packages",
    data,
  });

const postPicking = (data: { uniquePackagesGroupsIds: UUID[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "/wms/unique-packages-in-stock/create-picking",
    data,
  });

const postLoading = (data: { uniquePackagesGroupsIds: UUID[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "/wms/unique-packages-in-stock/create-loading",
    data,
  });

export const uniquePackagesFileFactory = (() => {
  return {
    longHeldPackagesCsv: (packagesIds: UUID[]) => ({
      url: `/orders/get-csv/long-held-packages?ids=${encodeURIComponent(packagesIds.join(","))}`,
      name: `Raport-długo-trzymanych-mebli-${fileFactoryUtils.getDate()}`,
    }),
  };
})();

export const uniquePackagesApi = {
  getLongHeldPackages,
  patchLongHeldPackage,
  postBulkUpdateLongHeldPackages,
  postLoading,
  postPicking,
};

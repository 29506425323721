import { NormalizedOrderShippingProgressItem } from "api/orders/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiPackageDetails } from "../../miloDesignSystem/atoms/icons/MdiPackageDetails";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { ProcessingStatusChoices } from "api/marketplace-statuses/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";

export const useOrdersColumns = (
  openSingleItemQuantitiesDetailsModal: (stateToSet: { orderId: number }) => void,
  orders: NormalizedOrderShippingProgressItem[],
  selectedOrders: NormalizedOrderShippingProgressItem[],
  setSelectedOrders: React.Dispatch<React.SetStateAction<NormalizedOrderShippingProgressItem[]>>,
) => {
  const isOrderSelected = (id: number): boolean => selectedOrders.some(order => order.id === id);

  const setOrderSelection = (order: NormalizedOrderShippingProgressItem) => {
    if (isOrderSelected(order.id))
      return setSelectedOrders(prevOrders =>
        prevOrders.filter(prevOrder => prevOrder.id !== order.id),
      );
    return setSelectedOrders(prevOrders => [...prevOrders, order]);
  };

  const areSomeOrdersSelected = Boolean(
    selectedOrders.length &&
      !orders.every(order => selectedOrders.some(selectedOrder => selectedOrder.id === order.id)) &&
      orders.some(order => selectedOrders.some(selectedOrder => selectedOrder.id === order.id)),
  );

  const areAllOrdersSelected =
    orders.every(order => selectedOrders.some(selectedOrder => selectedOrder.id === order.id)) &&
    Boolean(selectedOrders.length);

  const setAllOrdersSelection = () => {
    if (areAllOrdersSelected) return setSelectedOrders([]);
    const notSelectedOrder = orders.filter(
      order => !selectedOrders.some(selectedOrder => selectedOrder.id === order.id),
    );
    setSelectedOrders(prevOrders => [...prevOrders, ...notSelectedOrder]);
  };

  return useCreateTableColumns<NormalizedOrderShippingProgressItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "orderSelection",
        header: () => (
          <div className="d-flex align-items-end">
            <Checkbox
              checked={areAllOrdersSelected}
              indeterminate={areSomeOrdersSelected}
              onChange={setAllOrdersSelection}
              size="sx"
            />
          </div>
        ),
        size: 25,
        cell: info => {
          const order = info.getValue();

          return (
            <div className="my-0">
              <Checkbox
                checked={isOrderSelected(order.id)}
                onChange={() => setOrderSelection(order)}
                size="sx"
              />
            </div>
          );
        },
      }),
      columnHelper.link({
        header: "zamówienie",
        size: 110,
        to: row => `/orders/active/list/all?panelId=${row.id}`,
        textRenderer: row => row.signature,
        typographyProps: {
          fontSize: "12",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.releaseStatus, {
        id: "releaseStatus",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            wydane paczki
          </Typography>
        ),
        size: 85,
        cell: info => {
          const releaseStatus = info.getValue();

          return (
            <div className="d-flex w-100 align-items-baseline justify-content-end gap-1">
              <div className="d-flex align-items-center">
                <Typography
                  color={releaseStatus.releasedPackages > 0 ? "success500" : "neutralBlack88"}
                  fontSize="12"
                  fontWeight="700"
                >
                  {releaseStatus.releasedPackages}
                </Typography>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="700">
                  /{releaseStatus.totalPackages}
                </Typography>
              </div>
              <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "siqp",
        header: " ",
        size: 30,
        cell: info => {
          const orderReleaseProgress = info.getValue();

          return (
            <Tooltip title="Status realizacji zamówienia">
              <IconButton
                icon={MdiPackageDetails}
                onClick={() =>
                  openSingleItemQuantitiesDetailsModal({ orderId: orderReleaseProgress.id })
                }
                variant="transparent"
              />
            </Tooltip>
          );
        },
      }),
      columnHelper.date(row => row.releaseStatus.createdAt, {
        header: "data wydania",
        size: 80,
        typographyProps: {
          fontSize: "12",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row.externalServiceStatus, {
        header: "status w serwisie",
        size: 100,
        cell: info => {
          const externalServiceStatus = info.getValue();

          if (!externalServiceStatus) return <EmptyValue />;

          return <ExternalServiceStatus status={externalServiceStatus.status} />;
        },
      }),
      columnHelper.accessor(row => row.shipments, {
        header: "numery przesyłek",
        size: 130,
        cell: info => {
          const shipments = info.getValue();

          if (!Boolean(shipments.length)) return <EmptyValue />;

          return (
            <div className="w-100 d-flex align-items-center gap-1">
              <Typography fontSize="12" fontWeight="600" noWrap>
                {shipments[0].shipmentNumber}
              </Typography>
              {shipments.length > 1 && (
                <Typography color="deepPurple400" fontSize="12" fontWeight="600">
                  +{shipments.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.shipments, {
        header: "numery śledzenia",
        size: 130,
        cell: info => {
          const shipments = info.getValue();

          if (!Boolean(shipments.length)) return <EmptyValue />;

          return (
            <div className="w-100 d-flex align-items-center gap-1">
              <Typography fontSize="12" fontWeight="600" noWrap>
                {shipments[0].trackingNumber}
              </Typography>
              {shipments.length > 1 && (
                <Typography color="deepPurple400" fontSize="12" fontWeight="600">
                  +{shipments.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
    ];
  });
};

const ExternalServiceStatus = ({ status }: { status: ProcessingStatusChoices }) => {
  switch (status) {
    case ProcessingStatusChoices.FAILED:
      return <Tag label="Błąd" startIcon={MdiEmergencyHome} variant="danger" />;
    case ProcessingStatusChoices.FINISHED:
      return <Tag label="Przekazano" variant="success" />;
    case ProcessingStatusChoices.IN_PROGRESS:
      return <Tag label="W trakcie" variant="info" />;
    case ProcessingStatusChoices.NOT_IMPLEMENTED:
      return <Tag label="Brak implementacji" variant="warning" />;
    case ProcessingStatusChoices.NOT_STARTED:
      return <Tag label="Nie przekazano" type="outlined" variant="quaternary" />;
    default: {
      const exhaustiveCheck: never = status;
      console.error(`Unhandled external service status: ${exhaustiveCheck}`);
      return null;
    }
  }
};

import { shippingActions } from "api/shipping/actions";
import { ShipmentListItem, ShipmentStatus as ShipmentStatusChoices } from "api/shipping/models";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { ProgressBar } from "components/miloDesignSystem/atoms/progressBar";
import { Tag, TagProps } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { ShippingService, shippingServiceConstants } from "constants/shippingServiceConstants";
import { dateFns, dateUtils, pluralize } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "./ShippingShipments.module.css";
import { ShipmentStatus } from "./components/columns/ShipmentStatus";
import { countryToFlagDict } from "constants/countriesFlags";
import { CountryCode } from "CONSTANTS";
import { Order } from "api/orders/models";
import { marketplaceStatusesActions } from "api/marketplace-statuses/actions";
import { queryString } from "utilities";
import {
  DesiredStatusChoices,
  MarketplaceStatusItem,
  ProcessingStatusChoices,
} from "api/marketplace-statuses/models";
import { marketplaceStatusesUtilities } from "pages/orders/ordersList/rightPanel/marketplaceStatusesSection/utilities";
import { MdiRepeat } from "components/miloDesignSystem/atoms/icons/MdiRepeat";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { MdiKeyboardReturn } from "components/miloDesignSystem/atoms/icons/MdiKeyboardReturn";

export const useShippingShipmentsColumns = ({ orderIds }: { orderIds: Order["id"][] }) => {
  const downloadLabel = shippingActions.useDownloadShippingLabel();
  const search = queryString.stringify({ orderIds, desiredStatus: DesiredStatusChoices.SENT });
  const { data: marketplaceItems, isLoading } = marketplaceStatusesActions.useItems(search, {
    enabled: Boolean(orderIds.length),
  });

  return useCreateTableColumns<ShipmentListItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "sygnatura",
        size: 230,
        cell: info => {
          const shipment = info.getValue();
          return (
            <div className="d-flex w-100 justify-content-between align-items-center gap-2">
              <Typography fontSize="16" fontWeight="700" noWrap>
                {shipment.order.signature}
              </Typography>
              {shipment.isReturnShipment && (
                <Tag
                  label="zwrot"
                  startIcon={MdiKeyboardReturn}
                  type="outlined"
                  variant="warning"
                />
              )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.trackingNumbers.join(", "), {
        header: "nr przesyłki",
        size: 140,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status",
        size: 275,
        cell: info => {
          const row = info.getValue() as ShipmentListItem;

          return <ShipmentStatus shipment={row} />;
        },
      }),
      columnHelper.accessor(row => row.shippingService, {
        id: "shippingIcon",
        header: "",
        size: 60,
        cell: info => {
          const shippingService: ShipmentListItem["shippingService"] = info.getValue();
          if (!shippingService) return "";
          return (
            <div className={styles.courierLogoWrapper}>
              <img
                className={styles.courierLogo}
                alt="Kurier"
                src={shippingServiceConstants[shippingService.provider as ShippingService].logo}
              />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.shippingService?.name, {
        header: "konto",
        size: 90,
        typographyProps: { fontSize: "14" },
      }),
      columnHelper.accessor(row => row.countryCode, {
        id: "countryCode",
        header: () => (
          <Typography fontSize="12" fontWeight="400" className="text-center" color="neutralBlack48">
            kraj
          </Typography>
        ),
        size: 50,
        cell: info => {
          const countryCode: CountryCode = info.getValue();
          if (!countryCode) return <EmptyValue />;
          return (
            <div className="d-flex justify-content-center align-items-center flex-1 gap-1">
              <img alt="Flaga kraju" src={countryToFlagDict[countryCode]} />
              <Typography fontSize="10" fontWeight="700">
                {countryCode}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "cashOnDelivery",
        header: () => (
          <Typography
            fontSize="12"
            fontWeight="400"
            className="text-right mr-2"
            color="neutralBlack48"
          >
            pobranie
          </Typography>
        ),
        size: 100,
        cell: info => {
          const row = info.getValue();
          return (
            <AmountDisplay
              amount={row.cashOnDelivery || "0.00"}
              currency={row.currency}
              className="justify-content-end flex-1"
            />
          );
        },
      }),
      columnHelper.accessor(row => row.externalStatus, {
        header: "status u kuriera",
        size: 170,
        cell: info => {
          const status: string = info.getValue();
          if (!status) return <EmptyValue />;
          return <Tag label={status} variant="info" type="outlined" />;
        },
      }),
      columnHelper.text(
        row => {
          if (row.lastStatusUpdate) {
            return dateFns.format(new Date(row.lastStatusUpdate), "dd.MM.yyyy, HH:mm");
          }
        },
        {
          header: "w statusie od:",
          size: 108,
          typographyProps: {
            fontWeight: "600",
          },
        },
      ),
      columnHelper.accessor(row => row.order.id, {
        header: "przekazanie nr przesyłki",
        size: 160,
        cell: info => {
          const orderId = info.getValue();
          const marketplaceItem = marketplaceItems.find(
            marketplaceItem => String(marketplaceItem.orderId) === String(orderId),
          )!;

          return <MarketplaceShipping isLoading={isLoading} marketplaceItem={marketplaceItem} />;
        },
      }),
      columnHelper.accessor(row => row.externalProgress, {
        header: "progres",
        size: 140,
        cell: info => {
          const progress: number = info.getValue();
          return (
            <div className="d-flex align-items-center flex-1 gap-2">
              <ProgressBar progress={progress} />
              <Typography fontSize="12" fontWeight="700">
                {progress}%
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "dostarczyć do:",
        size: 160,
        cell: info => {
          const row = info.getValue() as ShipmentListItem;
          if (!row.shipmentDeadline) return <EmptyValue />;
          const differenceInDays = dateFns.differenceInDays(
            new Date(row.shipmentDeadline),
            new Date(),
          );
          const textColor = getShipmentDeadlineTextColor(differenceInDays);

          const displayDate = (() => {
            if (row.courierDeliveredDate) return "";
            if (differenceInDays === 0) {
              return "dzisiaj";
            }
            if (differenceInDays > 0) {
              return `za ${differenceInDays} ${pluralize.pl(differenceInDays, {
                singular: "dzień",
                plural: "dni",
                other: "dni",
              })}`;
            }
            if (differenceInDays < 0) {
              return `${-differenceInDays} ${pluralize.pl(-differenceInDays, {
                singular: "dzień",
                plural: "dni",
                other: "dni",
              })} temu`;
            }
          })();

          return (
            <div className="d-flex gap-2 align-items-baseline">
              <Typography fontSize="14" fontWeight="700" color={textColor}>
                {dateUtils.formatDateToDisplay(row.shipmentDeadline)}
              </Typography>
              <Typography fontSize="12" fontWeight="600" color={textColor}>
                {displayDate}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(
        row => {
          return row.calledPickupAt && dateUtils.formatDateToDisplay(row.calledPickupAt);
        },
        {
          header: "data nadania",
          size: 90,
        },
      ),
      columnHelper.accessor(row => row, {
        header: "etykieta",
        size: 50,
        cell: info => {
          const row: ShipmentListItem = info.getValue();
          return (
            <div>
              <IconButton
                icon={<MdiQrCode size="16" />}
                disabled={!row.shippingService || !row.trackingNumbers.length}
                onClick={async event => {
                  event.stopPropagation();
                  await downloadLabel(row);
                }}
                variant="gray"
              />
            </div>
          );
        },
      }),
    ];
  });
};

export const shipmentStatusToTagDict: Record<ShipmentStatusChoices, TagProps<string>> = {
  CREATE_SHIPMENT_SUCCESS: {
    label: "Zarejestrowano u kuriera",
    variant: "success",
    type: "filled",
  },
  CREATE_SHIPMENT_FAILED: { label: "Błąd podczas rejestracji", variant: "danger", type: "filled" },
  NO_REGISTERED: { label: "Nie nadano przesyłki", variant: "quaternary", type: "outlined" },
  REGISTRATION_IN_PROGRESS: {
    label: "W trakcie zamawiania",
    startIcon: MdiPoint,
    variant: "deepPurple50",
    type: "filled",
  },
  CANCELLATION_IN_PROGRESS: {
    label: "W trakcie anulowania",
    startIcon: MdiPoint,
    variant: "deepPurple50",
    type: "filled",
  },
  CANCELED_SHIPMENT_SUCCESS: {
    label: "Anulowano nadanie",
    variant: "warning",
    type: "filled",
  },
  CANCELED_SHIPMENT_FAILED: {
    label: "Błąd podczas anulowania",
    variant: "danger",
    type: "filled",
  },
  CALLED_PICKUP_FAILED: {
    label: "Błąd podczas nadania",
    variant: "warning",
    type: "filled",
  },
  CALLED_PICKUP_SUCCESS: { label: "Nadano przesyłkę", variant: "success", type: "filled" },
};

const getShipmentDeadlineTextColor = (differenceInDays: number): ColorPalette => {
  if (differenceInDays <= 5 && differenceInDays > 2) {
    return "warning500";
  }
  if (differenceInDays <= 2) {
    return "danger500";
  }
  return "neutralBlack88";
};

interface Props {
  marketplaceItem: MarketplaceStatusItem;
  isLoading: boolean;
}

export const MarketplaceShipping = ({ marketplaceItem, isLoading }: Props) => {
  if (isLoading) {
    return <Spinner size={20} />;
  }

  if (!marketplaceItem) {
    return (
      <Typography fontSize="14" fontWeight="600">
        {EMPTY_VALUE}
      </Typography>
    );
  }

  return (
    <div className="d-flex gap-2">
      {marketplaceStatusesUtilities.getProcessingStatusTag(marketplaceItem.processingStatus)}{" "}
      <ErrorStatus status={marketplaceItem} />
    </div>
  );
};

const ErrorStatus = ({ status }: { status: MarketplaceStatusItem }) => {
  const retryMutation = marketplaceStatusesActions.usePostRetry();
  if (status.processingStatus !== ProcessingStatusChoices.FAILED) return null;
  return (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        retryMutation.mutate({ statusEventId: status.id });
      }}
      isLoading={retryMutation.isLoading}
      variant="transparent"
      icon={MdiRepeat}
      size="small"
    />
  );
};

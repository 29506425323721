import { DraftInvoiceOrdersFilters } from "../../AddOrdersToDraft";
import styles from "../../AddOrdersToDraft.module.css";
import { Select } from "components/miloDesignSystem/molecules/select";
import { orderConstants } from "constants/orders";
import { Status } from "api/orders/models";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { CHANNELS, Channels, channels } from "CONSTANTS";
import { dateFns, dateUtils } from "utilities";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { ClickOutsideHandler } from "components/utils";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { useState } from "react";
import { ItemsOrService } from "../../AddOrdersToDraftContent";
import { OrderPaymentTypeChoices } from "api/orders/enums";
import { Line } from "components/miloDesignSystem/atoms/line";

interface Props {
  filters: DraftInvoiceOrdersFilters;
  itemsOrServices: ItemsOrService;
  setFilter: <T extends keyof DraftInvoiceOrdersFilters, U extends DraftInvoiceOrdersFilters[T]>(
    name: T,
    value: U,
  ) => void;
  setItemsOrServices: React.Dispatch<React.SetStateAction<ItemsOrService>>;
}

export const FiltersSection = ({
  filters,
  itemsOrServices,
  setFilter,
  setItemsOrServices,
}: Props) => {
  const [isCustomRangeSelected, setIsCustomRangeSelected] = useState(false);
  const months = getMonthsFilterContent();

  const resetPage = () => setFilter("page", 1);

  const isMonthTagSelected = (minDate: string, maxDate: string) => {
    return Boolean(
      minDate &&
        filters.minCreatedDate === minDate &&
        maxDate &&
        filters.maxCreatedDate === maxDate,
    );
  };

  return (
    <div className="d-flex mb-2 flex-column">
      <div className="mb-3">
        <Typography fontSize="14" fontWeight="700" className="mb-1">
          Uwzględniaj na fakturze:
        </Typography>

        <div className={styles.itemsOrServices}>
          <Radio
            checked={itemsOrServices === "onlyItems"}
            label="tylko towar"
            onClick={() => setItemsOrServices("onlyItems")}
            size="sm"
          />
          <Radio
            checked={itemsOrServices === "onlyServices"}
            label="tylko usługi"
            onClick={() => setItemsOrServices("onlyServices")}
            size="sm"
          />
          <Radio
            checked={itemsOrServices === null}
            label="towar i usługi"
            onClick={() => setItemsOrServices(null)}
            size="sm"
          />
        </div>
      </div>
      <div className="mb-3">
        <Typography fontSize="14" fontWeight="700" className="mb-1">
          Metoda ustalania ceny:
        </Typography>
        <div className={styles.itemsOrServices}>
          <Radio
            checked={filters.forceStrategy === OrderPaymentTypeChoices.DROP_SHIPPING}
            label="Cena za meble"
            onClick={() => {
              setFilter("forceStrategy", OrderPaymentTypeChoices.DROP_SHIPPING);
              resetPage();
            }}
            size="sm"
          />
          <Radio
            checked={filters.forceStrategy === OrderPaymentTypeChoices.AMOUNT}
            label="Wartość zamówienia"
            onClick={() => {
              setFilter("forceStrategy", OrderPaymentTypeChoices.AMOUNT);
              resetPage();
            }}
            size="sm"
          />
        </div>
      </div>

      <Line lineStyle="dashed" className="my-2" />

      <div className="d-flex align-items-center gap-3">
        <div className={styles.monthsFilter}>
          <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
            Zakres:
          </Typography>
          <div className="d-flex flex-1 gap-1 flex-nowrap">
            {months.map(month => (
              <div
                className={styles.selectableMonthTag}
                key={month.label}
                onClick={() => {
                  setFilter("minCreatedDate", month.minDate);
                  setFilter("maxCreatedDate", month.maxDate);
                  resetPage();
                  setIsCustomRangeSelected(false);
                }}
              >
                <Tag
                  label={month.label}
                  type={isMonthTagSelected(month.minDate, month.maxDate) ? "filled" : "outlined"}
                  variant={
                    isMonthTagSelected(month.minDate, month.maxDate) ? "deepPurple50" : "quaternary"
                  }
                />
              </div>
            ))}

            <Popover
              variant="DARK"
              hideOnClickOutside={false}
              content={({ hide }) => (
                <ClickOutsideHandler
                  onClickOutside={hide}
                  outsideClickIgnoreClass={ignoreClickOutsideClassName}
                >
                  <div className={styles.customDates}>
                    <Typography className="mb-1" fontSize="12" fontWeight="600">
                      Wybierz zakres dat:
                    </Typography>
                    <div className={styles.datePickersContainer}>
                      <DatePicker
                        calendarClassName={ignoreClickOutsideClassName}
                        isNullable
                        label="Od"
                        onChange={date => {
                          if (!date) {
                            setFilter("minCreatedDate", "");
                            resetPage();
                            if (!filters.maxCreatedDate) setIsCustomRangeSelected(false);
                          } else {
                            setFilter("minCreatedDate", dateUtils.formatDateToIso(date));
                            resetPage();
                            setIsCustomRangeSelected(true);
                          }
                        }}
                        placeholder="Wybierz"
                        value={filters.minCreatedDate}
                      />
                      <DatePicker
                        calendarClassName={ignoreClickOutsideClassName}
                        isNullable
                        label="Do"
                        onChange={date => {
                          if (!date) {
                            setFilter("maxCreatedDate", "");
                            resetPage();
                            if (!filters.minCreatedDate) setIsCustomRangeSelected(false);
                          } else {
                            setFilter("maxCreatedDate", dateUtils.formatDateToIso(date));
                            resetPage();
                            setIsCustomRangeSelected(true);
                          }
                        }}
                        placeholder="Wybierz"
                        value={filters.maxCreatedDate}
                      />
                    </div>
                  </div>
                </ClickOutsideHandler>
              )}
            >
              <div className={styles.selectableMonthTag}>
                <Tag
                  label={
                    isCustomRangeSelected
                      ? `${filters.minCreatedDate &&
                          dateUtils.formatDateToDisplay(
                            filters.minCreatedDate,
                          )} - ${filters.maxCreatedDate &&
                          dateUtils.formatDateToDisplay(filters.maxCreatedDate)}`
                      : "własny zakres"
                  }
                  type={isCustomRangeSelected ? "filled" : "outlined"}
                  variant={isCustomRangeSelected ? "deepPurple50" : "quaternary"}
                />
              </div>
            </Popover>
          </div>
        </div>

        <Select
          className={styles.filterSelect}
          label="Marketplace"
          items={[{ value: "", text: "Wszystkie", type: MenuItemType.TEXT }].concat(
            CHANNELS.map(channel => ({
              value: channel.id as Channels,
              text: channels[channel.id].name,
              type: MenuItemType.TEXT,
            })),
          )}
          onChange={channel => {
            setFilter("channels", channel as string);
            resetPage();
          }}
          selected={filters.channels}
        />
        <Select
          className={styles.filterSelect}
          label="Status"
          items={[
            {
              value: "",
              text: "Wszystkie",
              type: MenuItemType.TEXT,
            },
          ].concat(
            Object.entries(orderConstants.orderStatusDict).map(([value, text]) => ({
              value: value as Status,
              text,
              type: MenuItemType.TEXT,
            })),
          )}
          onChange={status => {
            setFilter("status", status as Status);
            resetPage();
          }}
          selected={filters.status}
        />
        <Select
          className={styles.filterSelect}
          label="Wystawiono fakturę na podmiot własny"
          items={[
            { value: "", text: "Wszystkie", type: MenuItemType.TEXT },
            { value: "true", text: "Tak", type: MenuItemType.TEXT },
            { value: "false", text: "Nie", type: MenuItemType.TEXT },
          ]}
          onChange={value => {
            setFilter("hasOwnEntityTradingDocument", value as string);
            resetPage();
          }}
          selected={filters.hasOwnEntityTradingDocument}
        />
        <div className={styles.monthsFilter}>
          <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
            Posiada fakturę
          </Typography>
          <div className="d-flex flex-1 gap-1 flex-nowrap">
            {Object.entries(tradingDocumentTags).map(([value, label]) => (
              <div
                className={styles.selectableMonthTag}
                key={label}
                onClick={() => {
                  setFilter("hasTradingDocument", value);
                  resetPage();
                }}
              >
                <Tag
                  label={label}
                  type={filters.hasTradingDocument === value ? "filled" : "outlined"}
                  variant={filters.hasTradingDocument === value ? "deepPurple50" : "quaternary"}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const tradingDocumentTags = {
  "": "WSZYSTKIE",
  true: "TAK",
  false: "NIE",
};

const getMonthsFilterContent = () => {
  const today = new Date();

  return [
    {
      label: "bieżący miesiąc",
      minDate: dateUtils.formatDateToIso(dateFns.startOfMonth(today)),
      maxDate: dateUtils.formatDateToIso(dateFns.endOfMonth(today)),
    },
    {
      label: dateFns.format(dateFns.subMonths(today, 1), "LLLL", { locale: dateFns.pl }),
      minDate: dateUtils.formatDateToIso(dateFns.startOfMonth(dateFns.subMonths(today, 1))),
      maxDate: dateUtils.formatDateToIso(dateFns.endOfMonth(dateFns.subMonths(today, 1))),
    },
    {
      label: dateFns.format(dateFns.subMonths(today, 2), "LLLL", { locale: dateFns.pl }),
      minDate: dateUtils.formatDateToIso(dateFns.startOfMonth(dateFns.subMonths(today, 2))),
      maxDate: dateUtils.formatDateToIso(dateFns.endOfMonth(dateFns.subMonths(today, 2))),
    },
  ];
};

const ignoreClickOutsideClassName = "add-orders-to-draft-popover-ignore-class-name";

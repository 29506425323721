import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { OrderTypeChoices } from "api/orders/enums";
import { ISODateTime, UUID } from "api/types";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiCheckSmall } from "components/miloDesignSystem/atoms/icons/MdiCheckSmall";
import { LongHeldPackage } from "api/unique-packages/models";
import styles from "./LongHeldPackages.module.css";
import rowStyles from "components/miloDesignSystem/molecules/table/components/body/components/row/Row.module.css";
import { Link } from "components/miloDesignSystem/atoms/link";
import { cx, dateUtils } from "utilities";

export type NormalizedLongHeldPackage = {
  id: UUID;
  indexName: string | null;
  order: {
    id: number;
    signature: string;
    externalId: string;
  } | null;
  isDelivered: boolean;
  releasedAt: ISODateTime | null;
  receivedAt: ISODateTime | null;
  settledAt: ISODateTime | null;
  daysInStock: string;
  locations: string[];
  packageLength: number;
  shouldBeSpanned: boolean;
  customer: string | null;
  productId: UUID;
};

export const normalizeLongHeldPackages = (data: LongHeldPackage[]): NormalizedLongHeldPackage[] => {
  return data.flatMap(packageGroup => {
    return packageGroup.packages.map((_package, index) => ({
      daysInStock: _package.daysInStock,
      id: _package.id,
      indexName: packageGroup.index?.name || packageGroup.index?.productName || null,
      isDelivered: _package.isDelivered,
      locations: [_package.location],
      order: packageGroup.order,
      receivedAt: _package.receivedAt,
      releasedAt: _package.releasedAt,
      settledAt: _package.settledAt,
      packageLength: packageGroup.packages.length,
      shouldBeSpanned: index === 0,
      customer: packageGroup.customer?.name || null,
      productId: packageGroup.id,
    })) as NormalizedLongHeldPackage[];
  });
};

export const useLongHeldPackagesColumns = (selectedRows: NormalizedLongHeldPackage[]) => {
  const isInSelectedRows = (id: UUID) =>
    Boolean(selectedRows.some(selectedRow => selectedRow.id === id));

  return useCreateTableColumns<NormalizedLongHeldPackage>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "produkt",
        size: 282,
        cell: info => {
          const row = info.getValue();

          if (!row.shouldBeSpanned) return <div></div>;

          return (
            <div
              className={cx(styles.spannedColumn, "pl-3", {
                [rowStyles.selected]: isInSelectedRows(row.id),
              })}
              style={{ height: `${38 * row.packageLength + 1 * row.packageLength + 1}px` }}
            >
              <Typography fontSize="16" fontWeight="700" noWrap>
                {row.indexName || EMPTY_VALUE}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "zamówienie",
        size: 130,
        cell: info => {
          const row = info.getValue();

          if (!row.shouldBeSpanned) return <div></div>;

          return (
            <div
              className={cx(styles.spannedColumn, {
                [rowStyles.selected]: isInSelectedRows(row.id),
              })}
              style={{ height: `${38 * row.packageLength + 1 * row.packageLength + 1}px` }}
            >
              <div onClick={event => event.stopPropagation()}>
                {row.order ? (
                  <Link
                    fontSize="14"
                    fontWeight="500"
                    to={getOrderRedirectUrl({
                      id: row.order.id,
                      isCanceled: false,
                      isSettled: false,
                      signature: row.order.signature,
                      type: OrderTypeChoices.STANDARD,
                    })}
                  >
                    {row.order.signature}
                  </Link>
                ) : (
                  <EmptyValue fontSize="14" fontWeight="500" />
                )}
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "nr obcy zamówienia",
        size: 110,
        cell: info => {
          const row = info.getValue();

          if (!row.shouldBeSpanned) return <div></div>;

          return (
            <div
              className={cx(styles.spannedColumn, {
                [rowStyles.selected]: isInSelectedRows(row.id),
              })}
              style={{ height: `${38 * row.packageLength + 1 * row.packageLength + 1}px` }}
            >
              <Typography fontSize="14" fontWeight="500" noWrap>
                {row.order?.externalId || EMPTY_VALUE}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "kontrahent",
        size: 120,
        cell: info => {
          const row = info.getValue();

          if (!row.shouldBeSpanned) return <div></div>;

          return (
            <div
              className={cx(styles.spannedColumn, {
                [rowStyles.selected]: isInSelectedRows(row.id),
              })}
              style={{ height: `${38 * row.packageLength + 1 * row.packageLength + 1}px` }}
            >
              <Typography fontSize="14" fontWeight="500" noWrap>
                {row.customer || EMPTY_VALUE}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.date(row => row.receivedAt, {
        header: "kiedy przyjęte",
        size: 98,
        typographyProps: { fontSize: "14", fontWeight: "700" },
      }),
      // columnHelper.accessor(row => row, {
      //   header: "l. przyjętych paczek",
      //   size: 110,
      //   cell: info => {
      //     const row = info.getValue();
      //     return (
      //       <div className="d-flex align-items-center gap-1">
      //         <Typography fontSize="14" fontWeight="700" color="success500">
      //           {row}
      //         </Typography>
      //         <Typography fontSize="14" fontWeight="700">
      //           z {row}
      //         </Typography>
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.date(row => row.releasedAt, {
        header: "kiedy wydane",
        size: 98,
        typographyProps: { fontSize: "14", fontWeight: "500" },
      }),
      columnHelper.accessor(row => row.daysInStock, {
        header: "l. dni",
        size: 200,
        cell: info => {
          const daysInStock = info.getValue();
          return (
            <Typography fontSize="14" fontWeight="500" noWrap>
              {dateUtils.parseIsoDuration(daysInStock).days}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.isDelivered, {
        header: "doręczenie",
        size: 100,
        cell: info => {
          const isDelivered = info.getValue();
          if (isDelivered) {
            return (
              <Tag label="DORECZONO" startIcon={MdiCheckSmall} variant="success" type="outlined" />
            );
          }
          return <Tag label="NIE DORĘCZONO" variant="quaternary" type="outlined" />;
        },
      }),
      columnHelper.accessor(row => row.settledAt, {
        header: "czy rozliczono?",
        size: 90,
        cell: info => {
          const settledAt = info.getValue();
          if (settledAt) {
            return <Tag label="TAK" startIcon={MdiCheckSmall} variant="success" />;
          }
          return <Tag label="NIE" variant="warning" />;
        },
      }),
      columnHelper.date(row => row.settledAt, {
        header: "kiedy rozliczono",
        size: 98,
        typographyProps: { fontSize: "14", fontWeight: "500" },
      }),
      columnHelper.text(row => (row.locations ? row.locations.join(", ") : null), {
        header: "lokalizacja",
        size: 110,
        typographyProps: { fontSize: "14", fontWeight: "800" },
      }),
    ];
  });
};

import { NormalizedOrderShippingProgressItem } from "api/orders/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { RightPanelSection } from "components/utils/drawer";
import { useFilters } from "hooks/useFilters";
import { useOrdersColumns } from "./useOrdersColumns";
import { orderActions } from "api/orders/actions";
import { OrderCollectionSource } from "api/wh-entries/enums";
import { useStateModal } from "hooks";
import { SingleItemQuantitiesDetails } from "pages/orders/ordersList/rightPanel/quantitiesTables/tables/singleItemQuantitiesDetails/SingleItemQuantitiesDetails";
import { useMemo, useState } from "react";
import { cx } from "utilities";
import { marketplaceStatusesActions } from "api/marketplace-statuses/actions";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection";

interface Props {
  collectionId: number;
  source: OrderCollectionSource;
}

interface Filters {
  orderGroupId?: number;
  page: number;
  pageSize: number;
  routeId?: number;
}

export const OrderReleaseProgressSection = ({ collectionId, source }: Props) => {
  const { searchParams, setFilter } = useFilters<Filters>({
    orderGroupId: source === OrderCollectionSource.ORDER_GROUP ? collectionId : undefined,
    page: 1,
    pageSize: 30,
    routeId: source === OrderCollectionSource.ROUTE ? collectionId : undefined,
  });
  const singleItemQuantitiesDetailsModal = useStateModal<{ orderId: number }>();
  const {
    data: orders,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = orderActions.useOrdersShippingProgress(searchParams);
  const [selectedOrders, setSelectedOrders] = useState<NormalizedOrderShippingProgressItem[]>([]);
  const normalizedOrderShippingProgressItems: NormalizedOrderShippingProgressItem[] = useMemo(() => {
    return orders.map(order => ({ ...order, isSelected: false }));
  }, [orders]);
  const tableProps = useTableFeatureConnector({
    rows: normalizedOrderShippingProgressItems,
  });
  const columns = useOrdersColumns(
    singleItemQuantitiesDetailsModal.open,
    normalizedOrderShippingProgressItems,
    selectedOrders,
    setSelectedOrders,
  );
  const setOrdersAsSentMutation = marketplaceStatusesActions.usePostOrdersStatusAsSent();

  return (
    <>
      <RightPanelSection
        padding="px-0"
        title={
          <div className="d-flex align-items-center justify-content-between">
            <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
              Status wysyłki w serwisie zewnętrznym
            </Typography>
            <Button
              className="text-uppercase"
              disabled={!Boolean(selectedOrders.length)}
              isLoading={setOrdersAsSentMutation.isLoading}
              onClick={() =>
                setOrdersAsSentMutation.mutate({
                  ordersIds: selectedOrders.map(selectedOrder => selectedOrder.id),
                })
              }
              size="small"
              variant="gray"
            >
              Przekaż wysłano w serwisie zewnętrznym
            </Button>
          </div>
        }
      >
        <div>
          <div className="d-flex flex-column">
            <Table<NormalizedOrderShippingProgressItem>
              columns={columns}
              error={error}
              isLoading={isLoading || isPreviousData}
              onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
              pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
              uiSchema={{ cell: { height: "36" }, header: { backgroundColor: "neutralWhite100" } }}
              {...tableProps}
              overrides={() => {
                return {
                  ...tableProps.overrides?.(),
                  hideHeader: !normalizedOrderShippingProgressItems.length,
                  noResultComponent: <EmptySection label="Brak elementów" />,
                  row: row => {
                    return {
                      className: cx(
                        {
                          "success-border":
                            row.releaseStatus.releasedPackages > 0 &&
                            row.releaseStatus.releasedPackages === row.releaseStatus.totalPackages,
                          "warning-border":
                            row.releaseStatus.releasedPackages < row.releaseStatus.totalPackages,
                        },
                        tableProps.overrides?.().row?.(row).className,
                      ),
                    };
                  },
                };
              }}
            />
          </div>
        </div>
      </RightPanelSection>
      {singleItemQuantitiesDetailsModal.isOpen && singleItemQuantitiesDetailsModal.state && (
        <SingleItemQuantitiesDetails
          close={singleItemQuantitiesDetailsModal.close}
          orderId={singleItemQuantitiesDetailsModal.state.orderId}
        />
      )}
    </>
  );
};
